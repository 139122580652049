import React from 'react'
import UilGithub from '@iconscout/react-unicons/dist/icons/uil-github'
import Button from './UIKit/Button'
import getEnv from '~/lib/getEnv'

interface Props {
  className?: string
  leagueInviteCode?: string
}

function GithubLoginButton({ className, leagueInviteCode }: Props) {
  const doGithubOauthRedirect = () => {
    if (leagueInviteCode) {
      document.cookie = `leagueInviteCode=${leagueInviteCode}; path=/; max-age=600; samesite=strict`
    }
    const clientId = getEnv().GITHUB_CLIENT_ID
    const returnUrl = `${window.location.origin}/github-login`
    window.location.href = `https://github.com/login/oauth/authorize?client_id=${clientId}&scope=user:email&redirect_uri=${encodeURIComponent(returnUrl)}`
  }

  return (
    <Button onClick={doGithubOauthRedirect} className={className}>
      <UilGithub className="mr-2" />
      Log in with GitHub
    </Button>
  )
}

export default GithubLoginButton
